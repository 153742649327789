import { createRef, useState } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";
import { useLocation } from "react-router-dom";
import { Link, UiText } from "@heetch/flamingo-react";

import FocusableInputField from "../../components/FocusableInputField";
import I18n from "../../components/I18n";
import Error from "../../components/Error";
import Layout from "../../components/Layout/Layout";
import SquareButton from "../../components/SquareButton/SquareButton";

import { useTracking } from "../../contexts/useTracking";
import {
  checkUndefinedOrNullString,
  displayError,
  isEmail,
  isRequired,
  validators,
} from "../../helpers";
import { SEGMENT_EVENTS } from "../../constants";

import { createUserAccount } from "../../api";

const UserInformationScreen = ({ transitionTo, password }) => {
  const [error, setError] = useState(false);
  const inputRef = createRef();

  const { trackEvent } = useTracking();
  const { t } = useTranslation();

  const trackContinueButton = formData =>
    trackEvent(SEGMENT_EVENTS.USER_INFORMATION_CONTINUE_TAPPED, formData);

  const trackSignedUp = formData =>
    trackEvent(SEGMENT_EVENTS.SIGNED_UP, formData);

  const onSubmit = formData =>
    createUserAccount({
      ...formData,
      password,
    })
      .then(({ data }) => {
        trackSignedUp();
        transitionTo(data);
      })
      .catch(displayError(setError));

  const email = checkUndefinedOrNullString(
    new URLSearchParams(useLocation()?.search)?.get("email"),
  );
  const firstName = checkUndefinedOrNullString(
    new URLSearchParams(useLocation()?.search)?.get("first_name"),
  );
  const lastName = checkUndefinedOrNullString(
    new URLSearchParams(useLocation()?.search)?.get("last_name"),
  );

  return (
    <Layout
      title={<I18n id="userInformation.title" />}
      subtitle={<I18n id="userInformation.helper" />}
    >
      {error && <Error error={error} />}

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting, values }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="email"
              validate={validators.compose(isRequired, isEmail)}
              defaultValue={email}
            >
              {({ input, meta }) => (
                <FocusableInputField
                  autoFocus
                  invalid={meta.touched && meta.invalid}
                  id="email"
                  type="email"
                  autoComplete="email"
                  inputRef={inputRef}
                  placeholder={t("form.labels.email")}
                  helper={
                    <>
                      {meta.touched && meta.error && (
                        <>
                          {meta.error}
                          <br />
                        </>
                      )}
                    </>
                  }
                  {...input}
                />
              )}
            </Field>

            <Field
              name="first_name"
              validate={isRequired}
              defaultValue={firstName}
            >
              {({ input, meta }) => (
                <FocusableInputField
                  invalid={meta.touched && meta.invalid}
                  id="firstname"
                  autoComplete="given-name"
                  placeholder={t("form.labels.firstname")}
                  helper={
                    <>
                      {meta.touched && meta.error && (
                        <>
                          {meta.error}
                          <br />
                        </>
                      )}
                    </>
                  }
                  {...input}
                />
              )}
            </Field>

            <Field
              name="last_name"
              validate={isRequired}
              defaultValue={lastName}
            >
              {({ input, meta }) => (
                <FocusableInputField
                  invalid={meta.touched && meta.invalid}
                  id="lastname"
                  autoComplete="family-name"
                  placeholder={t("form.labels.lastname")}
                  helper={
                    <>
                      {meta.touched && meta.error && (
                        <>
                          {meta.error}
                          <br />
                        </>
                      )}
                    </>
                  }
                  {...input}
                />
              )}
            </Field>

            <span className="ltr">
              <UiText
                variant={UiText.VARIANTS.subContent}
                textColor="#8b99a2"
                margin="1rem 0 0"
              >
                <I18n raw id="form.labels.cgu">
                  <Link target="_blank" href={t("form.labels.cguUrl")}>
                    placeholder text
                  </Link>
                  <Link target="_blank" href={t("form.labels.privacyUrl")}>
                    placeholder text
                  </Link>
                </I18n>
              </UiText>
            </span>

            <SquareButton
              onClick={() => trackContinueButton(values)}
              disabled={submitting}
              isLoading={submitting}
              type="submit"
              text={t("buttons.continue")}
              backgroundColor="rgb(77, 148, 255)"
            />
          </form>
        )}
      </Form>
    </Layout>
  );
};

UserInformationScreen.propTypes = {
  password: PropTypes.string.isRequired,
  transitionTo: PropTypes.func.isRequired,
};

export default UserInformationScreen;
