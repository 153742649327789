import PropTypes from "prop-types";
import { Text } from "@heetch/flamingo-react";

import AccountCreationItem from "../AccountCreationItem";
import ContactSupportButton from "../../ContactSupportButton";
import I18n from "../../I18n";
import PortalPanel from "../../PortalPanel";

import { useTracking } from "../../../contexts/useTracking";
import { isSignupFlow } from "../../../helpers";
import { SEGMENT_EVENTS } from "../../../constants";

const AuthRecoveryPanel = ({
  handleAccountCreation,
  authProvider,
  ...props
}) => {
  const { trackEvent } = useTracking();
  const trackSharedAccount = () =>
    trackEvent(SEGMENT_EVENTS.FACEBOOK_ISSUES_SHARED_PHONE_NUMBER_TAPPED);

  const SegmentEvents = {
    createAccount: {
      apple: SEGMENT_EVENTS.APPLE_RECOVERY_ISSUES_CREATE_ACCOUNT_TAPPED,
      facebook: SEGMENT_EVENTS.FACEBOOK_RECOVERY_ISSUES_CREATE_ACCOUNT_TAPPED,
    },
    sharedPhoneNumber: {
      apple: SEGMENT_EVENTS.APPLE_ISSUES_SHARED_PHONE_NUMBER_TAPPED,
      facebook: SEGMENT_EVENTS.FACEBOOK_ISSUES_SHARED_PHONE_NUMBER_TAPPED,
    },
  };

  return (
    <PortalPanel
      {...props}
      data-testid="panel"
      title={<I18n id="panels.oauth_recovery.title" />}
    >
      <Text>
        <I18n id="panels.oauth_recovery.share.title" />
        <br />
        <I18n id="panels.oauth_recovery.share.text" />
      </Text>

      <ContactSupportButton
        {...props}
        onClick={trackSharedAccount}
        page="login-issue-share-number"
      />

      {isSignupFlow() && (
        <>
          <Text>
            <I18n id="panels.password_recovery.new_account.title" />
            <br />
            <I18n id="panels.password_recovery.new_account.text" />
          </Text>

          <AccountCreationItem
            event={SegmentEvents.createAccount[authProvider]}
            onConfirm={handleAccountCreation}
          />
        </>
      )}
    </PortalPanel>
  );
};

AuthRecoveryPanel.propTypes = {
  handleAccountCreation: PropTypes.func.isRequired,
  authProvider: PropTypes.string.isRequired,
};

export default AuthRecoveryPanel;
