import { createContext, useContext, useEffect, useState } from "react";
import PropTypes from "prop-types";
import axios from "axios";
import * as Sentry from "../../services/Sentry";

export const FALLBACK_COUNTRY = "FR";

const UserLocationContext = createContext();

export const useUserLocation = () => {
  const context = useContext(UserLocationContext);

  if (!context) {
    throw new Error(
      `useUserLocation must be used within a UserLocationProvider`,
    );
  }

  return context;
};

export const fetchUserCountry = () =>
  axios
    .get("https://get.geojs.io/v1/ip/country.json")
    .then(({ data }) => {
      Sentry.addBreadcrumb({
        data,
        category: "log",
        message: `Country fetched: "${data?.country}"`,
        level: "log",
      });

      return data?.country || FALLBACK_COUNTRY;
    })
    .catch(err => {
      Sentry.addBreadcrumb({
        data: err,
        category: "error",
        message: `Couldn't fetch country, returning "${FALLBACK_COUNTRY}"`,
        level: "error",
      });

      return FALLBACK_COUNTRY;
    });

export const UserLocationProvider = ({ children, ...props }) => {
  const lng = new URLSearchParams(window.location?.search)
    .get("lng")
    ?.split("-"); // lng=ar-DZ;
  const [location, setLocation] = useState(
    lng?.length === 2 ? { country: lng[1] } : null,
  );

  useEffect(() => {
    if (!location) {
      fetchUserCountry().then(country => {
        setLocation({ country });
      });
    }
  }, [location]);

  return (
    <UserLocationContext.Provider value={location} {...props}>
      {location ? children : ""}
    </UserLocationContext.Provider>
  );
};

UserLocationProvider.propTypes = {
  children: PropTypes.node.isRequired,
};
