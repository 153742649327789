import { useState } from "react";
import PropTypes from "prop-types";
import { Alert, Link } from "@heetch/flamingo-react";
import { useTranslation } from "react-i18next";
import SquareButton from "../../SquareButton/SquareButton";

import I18n from "../../I18n";

import { useTracking } from "../../../contexts/useTracking";

const AccountCreationItem = ({ event, onConfirm }) => {
  const [isWarned, setIsWarned] = useState(false);

  const { trackEvent } = useTracking();
  const { t } = useTranslation();

  const trackAccountCreation = ({ status }) =>
    trackEvent(event, {
      status,
    });

  const hideAlert = () => setIsWarned(false);
  const showAlert = () => {
    trackAccountCreation({ status: "first_warning" });
    setIsWarned(true);
  };

  const onConfirmLinkClick = () => {
    trackAccountCreation({ status: "succeed" });
    onConfirm();
  };

  if (isWarned) {
    return (
      <Alert
        onClose={hideAlert}
        title={<I18n id="panels.password_recovery.new_account.alert.title" />}
      >
        <I18n
          raw
          id="panels.password_recovery.new_account.alert.text"
          values={{ Heetch: "Heetch" }}
        >
          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link onClick={onConfirmLinkClick} />
          <span className="ltr">{"{Heetch}"}</span>
        </I18n>
      </Alert>
    );
  }

  return (
    <SquareButton
      onClick={showAlert}
      text={t("panels.password_recovery.new_account.button")}
      backgroundColor="rgb(77, 148, 255)"
    />
  );
};

AccountCreationItem.propTypes = {
  event: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
};

export default AccountCreationItem;
