import { useState } from "react";
import PropTypes from "prop-types";
import qs from "query-string";

import i18n from "../../i18n";
import { getFromEnv, getUrlParams } from "../../helpers";
import { S } from "./styles";
import fbLogo from "../../assets/fb-logo-white.svg";

const FB_BASE_URL = `https://www.facebook.com/v2.8/dialog/oauth`;

const FACEBOOK_APP_ID = getFromEnv("FACEBOOK_APP_ID");
const OAUTH_CALLBACK = getFromEnv("OAUTH_CALLBACK");

const FbButton = ({ children, onClick, recovery, disabled }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [showFBbutton, setShowFBbutton] = useState(false);

  const redirectParams = qs.stringify({
    client_id: FACEBOOK_APP_ID,
    redirect_uri: `${OAUTH_CALLBACK}`,
    response_type: "token",
    state: qs.stringify({
      ...getUrlParams(),
      auth_provider: "facebook",
      recovery,
      lng: `${i18n.language}`,
    }),
  });

  const handleClick = () => {
    onClick();
    setShowFBbutton(true);
  };

  const handleButtonClick = () => {
    onClick();
    setIsLoading(true);
  };

  return (
    <>
      {showFBbutton || recovery ? (
        <a href={`${FB_BASE_URL}?${redirectParams}`}>
          <S.FacebookButton
            data-testid="fb-button"
            className="FbButton"
            disabled={disabled || isLoading}
            isLoading={isLoading}
            onClick={handleButtonClick}
          >
            <img
              data-testid="fb-logo"
              className="FbButton-logo"
              src={fbLogo}
              alt="Facebook"
            />
            {children}
          </S.FacebookButton>
        </a>
      ) : (
        <S.FacebookLink
          data-testid="fb-button"
          className="FbButton"
          disabled={disabled || isLoading}
          isLoading={isLoading}
          onClick={handleClick}
          role="button"
        >
          {children}
        </S.FacebookLink>
      )}
    </>
  );
};

FbButton.propTypes = {
  children: PropTypes.node,
  onClick: PropTypes.func,
  recovery: PropTypes.bool,
  disabled: PropTypes.bool,
};

FbButton.defaultProps = {
  children: undefined,
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  onClick: () => {},
  recovery: undefined,
  disabled: false,
};

export default FbButton;
