import Basil from "basil.js";
import qs from "query-string";

export const getFromEnv = key => window.Heetch.env[key];
export const getUrlParams = (path = window.location.search) => qs.parse(path);
export const redirectTo = path => window.location.assign(path);

export const getClientId = () => {
  const fromSearch = getUrlParams();
  const { state: fromHash } = getUrlParams(window.location.hash);
  return fromSearch.client_id || qs.parse(fromHash).client_id;
};

export const getLogoutUrl = () => `logout?${qs.stringify(getUrlParams())}`;

export const getFlow = () =>
  getClientId() === "driver-portal" ? "login" : "signup";

export const isLoginFlow = () => getFlow() === "login";
export const isSignupFlow = () => getFlow() === "signup";

export const localStorage = new Basil({
  namespace: "__HEETCH__",
});

export const checkUndefinedOrNullString = value =>
  value === "undefined" || value === "null" ? undefined : value;

export * from "./device-id";
export * from "./errors";
export * from "./form";
export * from "./tokens";
