import React, { useState } from "react";
import cx from "classnames";
import { Icon, IconButton, InputField } from "@heetch/flamingo-react";
import { useTranslation } from "react-i18next";
import "./PasswordField.css";

type Props = {
  inputRef: React.RefObject<HTMLInputElement>;
  invalid: boolean;
};

const PasswordField = ({ inputRef, invalid, ...props }: Props) => {
  const [isShown, setIsShown] = useState(false);
  const { t } = useTranslation();

  const toggleShown = () => {
    setIsShown(!isShown);
    inputRef.current?.focus();
  };

  const inputType = isShown ? "text" : "password";
  const buttonIcon = isShown ? Icon.ICONS.IconEyeClose : Icon.ICONS.IconEyeOpen;

  return (
    <div className={cx("PasswordField", { "is-invalid": invalid })}>
      <InputField
        id="password"
        type={inputType}
        autoComplete="new-password"
        invalid={invalid}
        inputRef={inputRef}
        placeholder={t("form.labels.password")}
        {...props}
      />

      <IconButton icon={buttonIcon} onClick={toggleShown} />
    </div>
  );
};

export default PasswordField;
