import styled from "styled-components/macro";

const SubtitleMessage = styled.div`
  color: #8b99a2;
  font-weight: 500;
  line-height: 20px;
  font-size: 14px;
  text-align: center;
  margin: 8px 0px;
`;

const NoCodeLink = styled.div`
  cursor: default;
  font-weight: 900;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  text-decoration-line: underline;
  margin-top: 16px;
  color: #8b99a2;
  place-content: space-evenly;
  :hover {
    cursor: pointer;
  }
`;

const S = { SubtitleMessage, NoCodeLink };

export { S };
