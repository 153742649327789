import { createRef } from "react";
import PropTypes from "prop-types";
import { Field, Form } from "react-final-form";
import { useTranslation } from "react-i18next";

import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";
import PasswordField from "../../components/PasswordField/PasswordField";
import SquareButton from "../../components/SquareButton/SquareButton";
import { useTracking } from "../../contexts/useTracking";
import { isMin, isRequired, validators } from "../../helpers";
import { SEGMENT_EVENTS, STATES } from "../../constants";

const CreatePasswordScreen = ({ transitionTo }) => {
  const { trackEvent } = useTracking();
  const inputRef = createRef();
  const { t } = useTranslation();

  const trackContinueButton = () =>
    trackEvent(SEGMENT_EVENTS.CREATE_PASSWORD_CONTINUE_TAPPED);

  const onSubmit = data =>
    transitionTo({
      state: STATES.USER_INFORMATION,
      ...data,
    });

  return (
    <Layout
      title={<I18n id="createPassword.title" />}
      subtitle={
        <>
          <I18n id="createPassword.helper" />
          <br />
          <I18n id="form.helpers.password" raw />
        </>
      }
    >
      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field
              name="password"
              validate={validators.compose(isRequired, isMin(8))}
            >
              {({ input, meta }) => (
                <PasswordField
                  autoFocus
                  invalid={meta.touched && meta.invalid}
                  autoComplete="new-password"
                  inputRef={inputRef}
                  helper={meta.touched && meta.error && meta.error}
                  {...input}
                />
              )}
            </Field>

            <SquareButton
              onClick={trackContinueButton}
              disabled={submitting}
              isLoading={submitting}
              type="submit"
              backgroundColor="rgb(77, 148, 255)"
              text={t("buttons.continue")}
            />
          </form>
        )}
      </Form>
    </Layout>
  );
};

CreatePasswordScreen.propTypes = {
  transitionTo: PropTypes.func.isRequired,
};

export default CreatePasswordScreen;
