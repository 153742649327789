import PropTypes from "prop-types";
import BecomeDriverButton from "../../components/BecomeDriverButton";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";

import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS, STATES } from "../../constants";
import { AuthProvider, AuthScreenProps } from "../../../@types"; // todo make this load over tsconfig.json
import { S } from "./styles";

const NoAccountWithOAuthScreen = (authentication_provider: AuthProvider) => {
  const NoAccountScreen = ({ transitionTo }: AuthScreenProps) => {
    const { trackEvent } = useTracking();
    const from_source = "phone_number";

    const handleTryAgainBtn = () => {
      transitionTo({ state: STATES.PHONE_OR_OAUTH });
      trackEvent(SEGMENT_EVENTS.TRY_OTHER_ID_TAPPED, {
        from_source,
      });
    };

    const trackBecomeDriver = () =>
      trackEvent(SEGMENT_EVENTS.BECOME_DRIVER_TAPPED, {
        from_source,
      });

    return (
      <Layout
        title={
          <I18n
            id="noAccountWithOAuth.title"
            values={{ authentication_provider }}
          />
        }
        subtitle={
          <I18n
            raw
            id="noAccountWithOAuth.helper"
            values={{ authentication_provider, Heetch: "Heetch" }}
          >
            <span className="ltr">{"{authentication_provider}"}</span>
            <span className="ltr" style={{ color: "#4D94FF" }}>
              {"{Heetch}"}
            </span>
          </I18n>
        }
        withRestartLink={false}
      >
        <S.TryAgainButton onClick={handleTryAgainBtn}>
          <I18n id="buttons.try-again" />
        </S.TryAgainButton>

        <BecomeDriverButton onClick={trackBecomeDriver} />
      </Layout>
    );
  };
  NoAccountScreen.propTypes = {
    transitionTo: PropTypes.func.isRequired,
  };
  return NoAccountScreen;
};

export default NoAccountWithOAuthScreen;
