import { useEffect } from "react";
import Cookies from "js-cookie";
import * as Sentry from "../../services/Sentry";

import { ssoRedirect } from "../../api";
import i18n from "../../i18n";
import { getAccessToken, getUrlParams, redirectTo } from "../../helpers";
import { IS_PROD, IS_STAGING } from "../../constants";

const AuthenticatedScreen = () => {
  useEffect(() => {
    const access_token = getAccessToken();
    const { redirect_uri } = getUrlParams();
    const redirectionUri = `${redirect_uri}?initial_language=${i18n?.language}`;

    if (access_token) {
      const expireIn = minutes => {
        const ret = new Date();
        ret.setTime(ret.getTime() + minutes * 60 * 1000);
        return ret;
      };

      Cookies.set("heetch_auth_token", access_token, {
        ...((IS_STAGING || IS_PROD) && {
          domain: (IS_STAGING && ".heetch.net") || (IS_PROD && ".heetch.com"),
          sameSite: "None",
          secure: true,
          expires: expireIn(1),
        }),
      });
    }

    if (
      process.env.NODE_ENV === "development" ||
      process.env.NODE_ENV === "test"
    ) {
      redirectTo(redirectionUri);
    } else {
      ssoRedirect({ redirect_uri: redirectionUri })
        .then(response => {
          if (response?.status === 204) redirectTo(redirectionUri);
        })
        .catch(() => {
          Sentry.addBreadcrumb({
            category: "error",
            message: "Invalid SSO signin source",
            level: "error",
          });
          redirectTo("https://www.heetch.com/");
        });
    }
  }, []);

  return <></>;
};

AuthenticatedScreen.propTypes = {};

export default AuthenticatedScreen;
