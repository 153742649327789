import { isValidPhoneNumber, parsePhoneNumber } from "libphonenumber-js";
import I18n from "../components/I18n";

export const getFormattedPhoneNumber = phoneNumber => {
  const { number } = parsePhoneNumber(`+${phoneNumber}}`);
  return number.toString();
};

export const validators = {
  compose:
    (...items) =>
    value =>
      items.reduce((error, validator) => error || validator(value), undefined),

  email: value =>
    // https://stackoverflow.com/questions/46155/how-to-validate-email-address-in-javascript
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
      value,
    ),

  phoneNumber: value => isValidPhoneNumber(`+${value}`),
};

export const isMin =
  count =>
  (value = "") =>
    value.length >= count ? undefined : (
      <I18n raw id="form.errors.is_min" values={{ count }}>
        <span className="ltr">{"{count}"}</span>
      </I18n>
    );

export const isEmail = (value = "") =>
  validators.email(value) ? undefined : <I18n id="form.errors.is_email" />;

export const isLengthEqualTo =
  count =>
  (value = "") =>
    value.length === count ? undefined : (
      <I18n id="form.errors.is_count" values={{ count }} />
    );

export const isVerificationCode = (value = "") =>
  // espace underscored added via react-input-verification-code
  isLengthEqualTo(4)(value.replace(/_+/, "")) ? (
    <I18n id="form.errors.is_verification_code" />
  ) : undefined;

export const isRequired = value =>
  value ? undefined : <I18n id="form.errors.is_required" />;

export const isPhoneNumber = value =>
  validators.phoneNumber(value) ? undefined : (
    <I18n id="form.errors.is_phone_number" />
  );
