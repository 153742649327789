import { useState } from "react";
import PropTypes from "prop-types";
import { Button, Icon, IconButton } from "@heetch/flamingo-react";

import { SCREENS } from "../../constants";
import { password, phoneNumber } from "../../stubs";

const screens = Object.keys(SCREENS)
  .filter(screen => screen !== "authenticated")
  .map(screen => ({
    state: screen,
    phone_number: phoneNumber,
    password,
  }));

const ScreenDebugger = ({ transitionTo }) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleNav = () => setIsOpen(!isOpen);

  return (
    <div
      style={{
        position: "absolute",
        top: 20,
        right: 20,
        textAlign: "right",
        zIndex: 100,
      }}
    >
      <IconButton
        icon={Icon.ICONS.IconOption}
        type="button"
        onClick={toggleNav}
        style={{ backgroundColor: "white !important" }}
      />

      {isOpen && (
        <div
          style={{
            padding: "var(--f-space--l)",
            backgroundColor: "rgba(255, 255, 255, 0.7)",
            textAlign: "left",
          }}
        >
          {screens.map(data => (
            <>
              <Button
                key={data.state}
                intent={Button.INTENTS.SECONDARY}
                style={{ display: "block", width: "100%", marginLeft: 0 }}
                onClick={() => {
                  setIsOpen(false);
                  transitionTo(data);
                }}
              >
                {data.state.toLowerCase()}
              </Button>
            </>
          ))}
        </div>
      )}
    </div>
  );
};

ScreenDebugger.propTypes = {
  transitionTo: PropTypes.func.isRequired,
};

export default ScreenDebugger;
