import { useState } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { FormField } from "@heetch/flamingo-react";

import Error from "../../components/Error";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";
import PhoneVerificationPanel from "../../components/panels/PhoneVerification";
import VerificationCode from "../../components/VerificationCode/VerificationCode.tsx";

import { verifyPhoneNumber } from "../../api";
import { displayError, isVerificationCode } from "../../helpers";
import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS } from "../../constants";
import { S } from "./styles";

const PhoneVerificationScreen = ({ transitionTo, phone_number }) => {
  const [error, setError] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);

  const openPanel = () => setIsPanelOpen(true);
  const closePanel = () => setIsPanelOpen(false);

  const { trackEvent } = useTracking();

  const trackContinueButton = ({ verification_code }) =>
    trackEvent(SEGMENT_EVENTS.CODE_VERIFICATION_CONTINUE_TAPPED, {
      verification_code_tapped: !!verification_code,
      phone_number,
    });

  const trackIssueButton = () =>
    trackEvent(SEGMENT_EVENTS.CODE_VERIFICATION_ISSUES_TAPPED, {
      phone_number,
    });

  const onSubmit = formData => {
    trackContinueButton(formData);
    return verifyPhoneNumber(formData)
      .then(({ data }) => transitionTo(data))
      .catch(displayError(setError));
  };

  return (
    <Layout title={<I18n id="phoneVerification.title" />}>
      <PhoneVerificationPanel
        isOpen={isPanelOpen}
        onClose={closePanel}
        phone_number={phone_number}
      />

      {error && <Error error={error} />}

      <Form onSubmit={onSubmit}>
        {({ handleSubmit }) => (
          <form onSubmit={handleSubmit}>
            <Field name="verification_code" validate={isVerificationCode}>
              {({ input, meta }) => (
                <FormField
                  id="verification_code"
                  helper={meta.touched && meta.error}
                >
                  <VerificationCode
                    isInvalid={meta.touched && meta.error}
                    onChange={input.onChange}
                    onComplete={handleSubmit}
                  />
                </FormField>
              )}
            </Field>

            <S.SubtitleMessage>
              <I18n raw id="phoneVerification.helper" values={{ phone_number }}>
                <span className="ltr" style={{ color: "#4D94FF" }}>
                  {"{phone_number}"}
                </span>
              </I18n>
            </S.SubtitleMessage>
          </form>
        )}
      </Form>

      <S.NoCodeLink
        role="button"
        onClick={() => {
          trackIssueButton();
          openPanel();
        }}
      >
        <I18n id="buttons.no-code" />
      </S.NoCodeLink>
    </Layout>
  );
};

PhoneVerificationScreen.propTypes = {
  transitionTo: PropTypes.func.isRequired,
  phone_number: PropTypes.string.isRequired,
};

export default PhoneVerificationScreen;
