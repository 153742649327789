/* eslint-disable no-unused-vars */
import cx from "classnames";
import ReactCodeInput from "react-verification-code-input";
import "./VerificationCode.css";

type Props = {
  isInvalid: boolean;
  onChange: (code: string) => void;
  onComplete: (code: string) => void;
};

const VerificationCode = ({ isInvalid, onChange, onComplete }: Props) => (
  <div
    className={cx("VerificationCode", {
      "is-invalid": isInvalid,
    })}
  >
    <ReactCodeInput
      fields={4}
      autoFocus
      onChange={onChange}
      onComplete={onComplete}
      placeholder={["_", "_", "_", "_"]}
    />
  </div>
);

export default VerificationCode;
