const fallbackErrorCode = "generic";

export const getErrorCode = err => {
  if (!err.response) {
    // network error
    return fallbackErrorCode;
  }

  if (!err.response.data) {
    // service is disable
    return fallbackErrorCode;
  }

  return err.response.data.code;
};

export const displayError = cb => err => cb(getErrorCode(err));
