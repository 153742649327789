import { useEffect } from "react";
import PropTypes from "prop-types";
import qs from "query-string";

import {
  getAccessCookie,
  getAccessToken,
  getUrlParams,
  removeAccessCookie,
  removeAccessToken,
} from "../../helpers";
import { deleteSession } from "../../api";
import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS } from "../../constants";

const LogoutScreen = ({ history }) => {
  const { trackEvent } = useTracking();

  useEffect(() => {
    const access_cookie = getAccessCookie();
    const access_token = getAccessToken();
    const redirect = () =>
      history.push({
        pathname: "/",
        search: qs.stringify(getUrlParams()),
      });

    trackEvent(SEGMENT_EVENTS.LOGGED_OUT);

    if (!access_token && !access_cookie) {
      redirect();
      return;
    }

    // it does not matter if the request throws an error or not
    // always remove the token + cookie and redirect the user
    const onDelete = () => {
      removeAccessCookie();
      removeAccessToken();
      if (!getAccessCookie()) redirect();
    };

    deleteSession().then(onDelete).catch(onDelete);
  }, [history, trackEvent]);

  return <></>;
};

LogoutScreen.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
};

export default LogoutScreen;
