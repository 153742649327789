import PropTypes from "prop-types";
import BecomeDriverButton from "../../components/BecomeDriverButton";
import Layout from "../../components/Layout/Layout";
import I18n from "../../components/I18n";

import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS, STATES } from "../../constants";
import { S } from "./styles";

const NoAccountWithPhoneScreen = ({ transitionTo, phone_number }) => {
  const { trackEvent } = useTracking();
  const from_source = "phone_number";

  const handleTryAgainBtn = () => {
    transitionTo({ state: STATES.PHONE_OR_OAUTH });
    trackEvent(SEGMENT_EVENTS.TRY_OTHER_ID_TAPPED, {
      phone_number,
      from_source,
    });
  };

  const trackBecomeDriver = () =>
    trackEvent(SEGMENT_EVENTS.BECOME_DRIVER_TAPPED, {
      from_source,
    });

  return (
    <Layout
      title={<I18n id="noAccountWithPhone.title" />}
      subtitle={
        <I18n
          raw
          id="noAccountWithPhone.helper"
          values={{ number: phone_number, Heetch: "Heetch" }}
        >
          <S.PhoneNumber className="ltr">{"{number}"}</S.PhoneNumber>
          <span className="ltr">{"{Heetch}"}</span>
        </I18n>
      }
      withRestartLink={false}
    >
      <S.TryAgainButton onClick={handleTryAgainBtn}>
        <I18n id="buttons.try-again" />
      </S.TryAgainButton>

      <BecomeDriverButton onClick={trackBecomeDriver} />
    </Layout>
  );
};

NoAccountWithPhoneScreen.propTypes = {
  transitionTo: PropTypes.func.isRequired,
  phone_number: PropTypes.string.isRequired,
};

export default NoAccountWithPhoneScreen;
