import Basil from "basil.js";
import topDomain from "@segment/top-domain";
import { v4 as uuidv4 } from "uuid";

const getTopDomain = () => {
  const domain = `.${topDomain(window.location.href)}`;
  return domain === "." ? null : domain;
};

export const setDeviceId = (device_id = uuidv4()) => {
  Basil.cookie.set("device_id", device_id, {
    domain: getTopDomain(),
    // Insecure sites (http:) can't set cookies with the Secure attribute
    // https://developer.mozilla.org/en-US/docs/Web/HTTP/Headers/Set-Cookie
    secure: process.env.NODE_ENV !== "test",
    sameSite: "lax",
  });

  return device_id;
};

export const getDeviceId = () => {
  const device_id = Basil.cookie.get("device_id");
  return device_id || setDeviceId();
};
