import PropTypes from "prop-types";
import qs from "query-string";
import { useTranslation } from "react-i18next";
import { Text } from "@heetch/flamingo-react";

import I18n from "../I18n";
import { getDeviceId } from "../../helpers";
import { CONTACT_LINK } from "../../constants";
import SquareButton from "../SquareButton/SquareButton";

const ContactSupportButton = ({ onClick, page, phone_number }) => {
  const urlParams = qs.stringify({
    device_id: getDeviceId(),
    page: `sso-${page}`,
    phone_number,
  });
  const { t } = useTranslation();

  const contactLinkUrl = `${CONTACT_LINK}?${urlParams}`;

  return (
    <I18n raw id="panels.contact_information">
      {/* some languages will display contact button - see <0> and the nested <0> inside the translation */}
      <a
        data-testid="support-button"
        href={contactLinkUrl}
        target="_blank"
        rel="noopener noreferrer"
        style={{ outline: "none" }}
      >
        <SquareButton
          onClick={onClick}
          text={t("buttons.contact_support")}
          backgroundColor="#ff4d95"
        />
      </a>

      {/* other languages will embed other information - see <1> inside the translation */}
      <Text className="ltr whiteSpace left" />
    </I18n>
  );
};

ContactSupportButton.propTypes = {
  onClick: PropTypes.func,
  page: PropTypes.string.isRequired,
  phone_number: PropTypes.string,
};

ContactSupportButton.defaultProps = {
  onClick: undefined,
  phone_number: undefined,
};

export default ContactSupportButton;
