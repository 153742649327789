import React, { useCallback, useEffect, useState } from "react";
import PropTypes from "prop-types";
import * as Sentry from "@sentry/browser";
import * as H from "history";
import { RouteComponentProps } from "react-router";

import ScreenDebugger from "../ScreenDebugger";

import {
  getAccessToken,
  getLogoutUrl,
  getUrlParams,
  redirectTo,
  setAccessToken,
} from "../../helpers";

import { useTracking } from "../../contexts/useTracking";
import { getSession, setAuthorizationToken } from "../../api";
import {
  DEFAULT_DIRECT_URI,
  IS_DEV,
  IS_STAGING,
  SCREENS,
  STATES,
} from "../../constants";
import {
  AuthProvider,
  AuthScreenState,
  AuthState,
  SessionState,
} from "../../../@types";

export const checkParams = () => {
  const urlParams = getUrlParams();
  const { debug, redirect_uri, client_id } = urlParams;

  if (!redirect_uri || !client_id) {
    // eslint-disable-next-line no-console
    console.error(
      "Missing `redirect_uri` or `client_id` url params:",
      urlParams,
    );

    if (debug || IS_DEV) return;
    redirectTo(DEFAULT_DIRECT_URI);
  }
};

export const storeToken = (token: string) => {
  setAuthorizationToken(token);
  setAccessToken(token);
};

export const getNextScreen = ({
  authentication_provider,
  state,
  phone_number_authentication_providers,
}: AuthScreenState): AuthState => {
  const { client_id } = getUrlParams();
  const isLoginFlow = client_id === "driver-portal";
  const isFacebookProvider = authentication_provider === "facebook";
  const isAppleProvider = authentication_provider === "apple";
  const isOAuthProvider = isFacebookProvider || isAppleProvider;

  const isShouldRecoverWithOtherOAuthProvider =
    authentication_provider &&
    phone_number_authentication_providers?.length &&
    !phone_number_authentication_providers.includes(authentication_provider);

  const isRecoveryOfAnOAuthProvider =
    (isOAuthProvider && state === "authentication_required") ||
    (state === "user_account_required" &&
      isShouldRecoverWithOtherOAuthProvider);

  let nextState = state;

  if (isRecoveryOfAnOAuthProvider) {
    const providerToRecover = (phone_number_authentication_providers &&
      phone_number_authentication_providers[0]) as AuthProvider;
    let recoveryState: AuthState | null = null;
    if (providerToRecover === "apple") {
      recoveryState = "apple_recovery";
    } else if (providerToRecover === "facebook") {
      recoveryState = "facebook_recovery";
    } else if (providerToRecover === "phone_number-password") {
      // To recover phone_number-password we just need to ask for password
      // since the phone number is already confirmed.
      recoveryState = "enter_password";
    }
    if (!recoveryState) {
      throw new Error(
        "could not find the right recovery state for auth provider",
      );
    }
    return recoveryState;
  }
  if (state === "user_account_required") {
    if (isLoginFlow) {
      switch (authentication_provider) {
        case "apple":
          return "NO_ACCOUNT_WITH_APPLE";
        case "facebook":
          return "NO_ACCOUNT_WITH_FACEBOOK";
        default:
          return "NO_ACCOUNT_WITH_PHONE";
      }
    }

    return isOAuthProvider ? "user_information" : "create_password";
  }

  if (state === "authentication_required") {
    // eslint-disable-next-line no-nested-ternary
    nextState = isFacebookProvider
      ? "facebook_recovery"
      : isAppleProvider
      ? "apple_recovery"
      : "enter_password";
  }

  return nextState;
};

interface AuthScreenProps {
  // eslint-disable-next-line no-unused-vars
  transitionTo: (data: AuthScreenState) => void;
  history: H.History<AuthScreenState>;
}
interface AuthScreen extends React.FC<AuthScreenProps> {}
interface Props extends RouteComponentProps<any, any, AuthScreenState> {}

const Manager = ({ history, location }: Props) => {
  const [screen, setScreen] = useState<AuthScreenState>(location.state || {});

  const { trackPage } = useTracking();
  const { debug } = getUrlParams();

  const Screen = SCREENS[screen.state] as AuthScreen;

  const transitionTo = useCallback(
    (data: AuthScreenState) => {
      const sessionState = data.state as SessionState;
      const nextScreen = getNextScreen(data);
      const { access_token } = data;

      if (access_token) storeToken(access_token);
      if (screen?.state !== nextScreen)
        setScreen({ ...screen, ...data, state: nextScreen, sessionState });
    },
    [screen],
  );

  // get the current user session
  // and display the corresponding screen
  // if the session is invalid, triggers a logout
  useEffect(() => {
    // redirects to `DEFAULT_DIRECT_URI`
    // if missing params
    checkParams();

    if (!screen.state || screen?.state !== screen?.sessionState) {
      const access_token = getAccessToken();
      if (access_token && !debug) {
        storeToken(access_token);
        getSession()
          .then(({ data: sessionData }) => {
            transitionTo(sessionData);
          })
          .catch(() => {
            history.push(getLogoutUrl());
          });
      } else {
        transitionTo({ state: STATES.PHONE_OR_OAUTH });
      }
    }
  }, [debug, history, screen?.sessionState, screen?.state, transitionTo]);

  // when transitioning from a screen to another
  // add logs into Sentry
  // and send a Segment tracking page event
  useEffect(() => {
    const { state, ...extra } = screen;

    if (state) {
      Sentry.addBreadcrumb({
        category: "transition",
        level: "info",
        data: { state, ...extra },
      });

      trackPage(state, extra);
    }
  }, [screen, trackPage]);

  return (
    <>
      {(IS_DEV || IS_STAGING) && debug && (
        <ScreenDebugger transitionTo={transitionTo} />
      )}

      {Screen && (
        <Screen history={history} transitionTo={transitionTo} {...screen} />
      )}
    </>
  );
};

Manager.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired,
  }).isRequired,
  location: PropTypes.shape({
    state: PropTypes.shape({}),
  }).isRequired,
};

export default Manager;
