import { Text } from "@heetch/flamingo-react";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

import ContactSupportButton from "../../ContactSupportButton";
import Error from "../../Error";
import I18n from "../../I18n";
import PortalPanel from "../../PortalPanel";
import SquareButton from "../../SquareButton/SquareButton";

import { sendVerificationCode } from "../../../api";
import { RECAPTCHA_ERROR_CODE, SEGMENT_EVENTS } from "../../../constants";
import { useTracking } from "../../../contexts/useTracking";
import { displayError } from "../../../helpers";
import { useReCaptcha } from "../../../hooks/useReCaptcha";

export const DELAY_BEFORE_CLOSING = 700;

const reCaptchaCheckboxId = "reCaptchaCheckboxId-PhoneVerificationCode";

const PhoneVerificationPanel = ({ onClose, ...props }) => {
  const [error, setError] = useState(false);
  const [isNewCodeSent, setIsNewCodeSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [reCaptchaCheckboxAttempt, setReCaptchaCheckboxAttempt] =
    useState(false);
  const [reCaptchaCheckboxToken, setReCaptchaCheckboxToken] = useState("");

  const { i18n, t } = useTranslation();
  const { initializeReCaptcha, getReCaptchaToken, loadReCaptchaCheckbox } =
    useReCaptcha(i18n.language);
  const { trackEvent } = useTracking();

  const trackContactSupportClicked = () =>
    trackEvent(SEGMENT_EVENTS.GOT_NO_SMS_SHARED_PHONE_NUMBER_TAPPED);

  const submitWithReCaptcha = ({ recaptcha_token, challenge }) => {
    const onSuccess = () => {
      setIsNewCodeSent(true);
      setTimeout(() => {
        onClose();
        setIsNewCodeSent(false);
      }, DELAY_BEFORE_CLOSING);
    };

    sendVerificationCode({ recaptcha_token, challenge })
      .then(() => {
        onSuccess();
        setIsLoading(false);
      })
      .catch(apiError => {
        setIsLoading(false);
        if (
          apiError?.response?.status === 422 &&
          apiError?.response?.data?.code === RECAPTCHA_ERROR_CODE &&
          !reCaptchaCheckboxAttempt
        ) {
          loadReCaptchaCheckbox({
            reCaptchaCheckboxId,
            onRecaptchaCheckboxLoad: () => setReCaptchaCheckboxAttempt(true),
            onRecaptchaCheckboxSuccess: token =>
              setReCaptchaCheckboxToken(token),
          });
        } else {
          displayError(setError(apiError?.message));
        }
      });
  };

  const handleSendVerificationCode = () => {
    if (isNewCodeSent) {
      return; // hack to not override disable css
    }
    trackEvent(SEGMENT_EVENTS.GOT_NO_SMS_SEND_AGAIN_TAPPED);
    setIsLoading(true);

    if (reCaptchaCheckboxToken) {
      submitWithReCaptcha({
        challenge: "checkbox",
        recaptcha_token: reCaptchaCheckboxToken,
      });
    } else {
      getReCaptchaToken({
        onReCaptchaTokenValidate: recaptcha_token =>
          submitWithReCaptcha({
            challenge: "score",
            recaptcha_token,
          }),
      });
    }
  };

  useEffect(() => {
    if (!window?.grecaptcha) initializeReCaptcha();
  }, [initializeReCaptcha]);

  return (
    <PortalPanel
      {...props}
      data-testid="panel"
      onClose={onClose}
      title={<I18n id="panels.phone_verification.title" />}
    >
      {error && <Error error={error} />}

      {isNewCodeSent ? (
        <SquareButton backgroundColor="#5CC689" IconCheck />
      ) : (
        <>
          <div id={reCaptchaCheckboxId} />

          <SquareButton
            data-testid="send-verification-code-button"
            className={isNewCodeSent ? "is-success" : ""}
            onClick={handleSendVerificationCode}
            disabled={isLoading}
            isLoading={isLoading}
            backgroundColor="#4D94FF"
            text={t("panels.phone_verification.send_again.button")}
          />
        </>
      )}

      <Text>
        <I18n id="panels.phone_verification.new_phone.title" />
        <br />
        <I18n id="panels.phone_verification.new_phone.text" />
      </Text>

      <ContactSupportButton
        onClick={trackContactSupportClicked}
        page="no-sms"
        {...props}
      />
    </PortalPanel>
  );
};

PhoneVerificationPanel.propTypes = {
  onClose: PropTypes.func.isRequired,
};

export default PhoneVerificationPanel;
