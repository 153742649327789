import { useEffect } from "react";
import qs from "query-string";
import { useHistory } from "react-router";

import i18n from "../../i18n";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";

import {
  getAccessToken,
  getErrorCode,
  getUrlParams,
  isLoginFlow,
  setAccessToken,
} from "../../helpers";
import {
  createFacebookSession,
  linkFacebookSession,
  createAppleSession,
  linkAppleSession,
  setAuthorizationToken,
} from "../../api";
import { STATES } from "../../constants";
import { AuthProvider, AuthScreenState } from "../../../@types";

const OAUTH_CALLBACKS = {
  link: {
    apple: linkAppleSession,
    facebook: linkFacebookSession,
  },

  create: {
    apple: createAppleSession,
    facebook: createFacebookSession,
  },
};

const RECOVERY_STATE = {
  apple: STATES.APPLE_RECOVERY,
  facebook: STATES.FACEBOOK_RECOVERY,
};

const OAuthCallbackScreen = () => {
  const { error, state: errorState, code, auth_provider } = getUrlParams();
  const { access_token, state: successState } = getUrlParams(
    window.location.hash,
  );
  const accessToken = getAccessToken();
  const { recovery, ...redirectParams } = qs.parse(
    (errorState || successState) as string,
  );

  const history = useHistory();

  if (accessToken) {
    setAuthorizationToken(accessToken);
  }

  useEffect(() => {
    const authProvider = (redirectParams.auth_provider ||
      auth_provider) as AuthProvider;

    const redirect = ({ pathname = "/", state }: AuthScreenState) => {
      if (i18n.language !== redirectParams?.lng) {
        const { lng }: any = redirectParams;
        i18n.changeLanguage(lng);
      }
      history.push({
        pathname,
        search: `?${qs.stringify(redirectParams)}`,
        state,
      });
    };

    if (error) {
      redirect({
        state:
          recovery && authProvider
            ? RECOVERY_STATE[authProvider]
            : STATES.PHONE_OR_OAUTH,
      });
      return;
    }

    if (
      (authProvider === "apple" && !code) ||
      (authProvider === "facebook" && !access_token)
    ) {
      redirect({ pathname: "/logout" } as AuthScreenState);
      return;
    }

    const callOAuthAPI = recovery
      ? OAUTH_CALLBACKS.link[authProvider]
      : OAUTH_CALLBACKS.create[authProvider];

    callOAuthAPI({ access_token: access_token as string, code })
      .then(({ data }) => {
        if (data?.access_token) {
          setAccessToken(data.access_token);
        }
        redirect({ state: data });
      })
      .catch(err => {
        redirect({ errorCode: getErrorCode(err) } as AuthScreenState);
      });
  }, [
    access_token,
    auth_provider,
    code,
    error,
    history,
    recovery,
    redirectParams,
  ]);

  const loginI18nHelperId = isLoginFlow()
    ? "phoneOrOAuth.helper-login"
    : "phoneOrOAuth.helper-signup";

  const title = recovery ? (
    <I18n
      raw
      id="oauthRecovery.title"
      values={{ authentication_provider: auth_provider }}
    >
      <span className="ltr">{"{authentication_provider}"}</span>
    </I18n>
  ) : (
    <I18n id="phoneOrOAuth.title" />
  );

  const subtitle = recovery ? (
    <I18n
      raw
      id="oauthRecovery.helper"
      values={{
        Heetch: "Heetch",
        authentication_provider: auth_provider,
      }}
    >
      <span className="ltr">{"{Heetch}"}</span>
      <span className="ltr">{"{authentication_provider}"}</span>
    </I18n>
  ) : (
    <I18n id={loginI18nHelperId} />
  );

  return (
    <Layout title={title} withRestartLink={!!recovery} subtitle={subtitle} />
  );
};

export default OAuthCallbackScreen;
