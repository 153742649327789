import { useState } from "react";
import { useTranslation } from "react-i18next";
import PropTypes from "prop-types";
import { Alert, Text } from "@heetch/flamingo-react";

import AccountCreationItem from "../AccountCreationItem";
import ContactSupportButton from "../../ContactSupportButton";
import Error from "../../Error";
import I18n from "../../I18n";
import PortalPanel from "../../PortalPanel";
import SquareButton from "../../SquareButton/SquareButton";

import { recoverPassword } from "../../../api";
import { useTracking } from "../../../contexts/useTracking";
import { SEGMENT_EVENTS } from "../../../constants";

const PasswordRecoveryPanel = ({ handleAccountCreation, ...props }) => {
  const [error, setError] = useState(undefined);
  const [isEmailSent, setIsEmailSent] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const { trackEvent } = useTracking();
  const { t } = useTranslation();

  const trackForgotPassword = ({ status }) =>
    trackEvent(SEGMENT_EVENTS.PASSWORD_ISSUES_FORGOT_PASSWORD_TAPPED, {
      status,
    });

  const trackPasswordNotWorking = () =>
    trackEvent(SEGMENT_EVENTS.PASSWORD_ISSUES_NOT_WORKING_TAPPED);

  const trackSharedNumber = () =>
    trackEvent(SEGMENT_EVENTS.PASSWORD_ISSUES_SHARED_PHONE_NUMBER_TAPPED);

  const handleRecoverPassword = () => {
    setError(undefined);
    setIsLoading(true);

    return recoverPassword()
      .then(() => {
        setIsEmailSent(true);
        trackForgotPassword({ status: "success" });
        setIsLoading(false);
      })
      .catch(err => {
        setError(err);
        trackForgotPassword({ status: "failed" });
        setIsLoading(false);
      });
  };

  return (
    <PortalPanel
      {...props}
      data-testid="panel"
      title={<I18n id="panels.password_recovery.title" />}
    >
      <Text>
        <I18n id="panels.password_recovery.forgot.title" />
        <br />
        <I18n id="panels.password_recovery.forgot.text" />
      </Text>

      {isEmailSent && (
        <div data-testid="alert-success">
          <Alert
            type={Alert.TYPES.SUCCESS}
            title={<I18n id="panels.password_recovery.alert_success.title" />}
          >
            <I18n id="panels.password_recovery.alert_success.text" />
          </Alert>
        </div>
      )}

      {error && <Error error="password_recovery" />}

      {!isEmailSent && (
        <SquareButton
          data-testid="send-email-button"
          onClick={handleRecoverPassword}
          disabled={isLoading}
          isLoading={isLoading}
          backgroundColor="rgb(77, 148, 255)"
          text={t("panels.password_recovery.forgot.button")}
        />
      )}

      <Text>
        <I18n id="panels.password_recovery.invalid.title" />
        <br />
        <I18n id="panels.password_recovery.invalid.text" />
      </Text>

      <ContactSupportButton
        {...props}
        onClick={trackPasswordNotWorking}
        page="login-issue-password"
      />

      <Text>
        <I18n id="panels.password_recovery.share.title" />
        <br />
        <I18n id="panels.password_recovery.share.text" />
      </Text>

      <ContactSupportButton
        {...props}
        onClick={trackSharedNumber}
        page="login-issue-share-number"
      />

      <Text>
        <I18n id="panels.password_recovery.new_account.title" />
        <br />
        <I18n id="panels.password_recovery.new_account.text" />
      </Text>

      <AccountCreationItem
        event={SEGMENT_EVENTS.PASSWORD_ISSUES_CREATE_ACCOUNT_TAPPED}
        onConfirm={handleAccountCreation}
      />
    </PortalPanel>
  );
};

PasswordRecoveryPanel.propTypes = {
  handleAccountCreation: PropTypes.func.isRequired,
};

export default PasswordRecoveryPanel;
