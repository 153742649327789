import styled from "styled-components";

const RestartLink = styled.div`
  text-align: center;
  text-decoration: underline;
  color: white;
  font-weight: 900;
  margin-top: 16px;
  :hover {
    cursor: pointer;
  }
  @media (max-height: 490px) {
    margin-top: -14px;
  }
`;

const S = { RestartLink };
export { S };
