import { useEffect, useState } from "react";
import { getFlow, getFromEnv } from "../helpers";

export const useReCaptcha = language => {
  const RECAPTCHA_SITE_KEY = getFromEnv("RECAPTCHA_SITE_KEY");
  const RECAPTCHA_CHECKBOX_KEY = getFromEnv("RECAPTCHA_CHECKBOX_KEY");
  const [init, setInitialize] = useState(false);

  const initializeReCaptcha = () => {
    setInitialize(true);
  };

  const getReCaptchaToken = ({ onReCaptchaTokenValidate }) => {
    window?.grecaptcha?.enterprise?.ready(async () => {
      const token: string = await window.grecaptcha.enterprise.execute(
        RECAPTCHA_SITE_KEY,
        { action: getFlow() },
      );
      if (token) onReCaptchaTokenValidate(token);
    });
  };

  const loadReCaptchaCheckbox = ({
    reCaptchaCheckboxId,
    onRecaptchaCheckboxLoad,
    onRecaptchaCheckboxSuccess,
  }) => {
    window?.grecaptcha?.enterprise?.ready(() => {
      // Remove existing reCAPTCHA scorebased script
      const existingScript = document.querySelector(
        'script[src^="https://www.google.com/recaptcha/enterprise.js"]',
      );
      if (existingScript) existingScript.remove();

      // Load checkbox reCAPTCHA checkbox script
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/api.js?render=explicit&hl=${language}`;
      script.async = true;
      script.defer = true;
      document.head.appendChild(script);

      script.onload = () => {
        const reCaptchaCheckbox = window.grecaptcha.enterprise.render(
          reCaptchaCheckboxId,
          {
            sitekey: RECAPTCHA_CHECKBOX_KEY,
            action: getFlow(),
            callback: () =>
              onRecaptchaCheckboxSuccess(
                window.grecaptcha.enterprise.getResponse(reCaptchaCheckbox),
              ),
          },
        );
        onRecaptchaCheckboxLoad();
      };
    });
  };

  useEffect(() => {
    if (!window?.grecaptcha && init) {
      const script = document.createElement("script");
      script.src = `https://www.google.com/recaptcha/enterprise.js?render=${RECAPTCHA_SITE_KEY}&hl=${language}`;
      document.body.appendChild(script);
    }
  }, [RECAPTCHA_SITE_KEY, init, language]);

  return {
    initializeReCaptcha,
    getReCaptchaToken,
    loadReCaptchaCheckbox,
  };
};
