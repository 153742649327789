import styled from "styled-components/macro";

const ConnexionIssueButton = styled.div`
  font-weight: 900;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  text-decoration-line: underline;
  margin-top: 16px;
  color: #8b99a2;
  place-content: space-evenly;
  cursor: default;
`;

const S = { ConnexionIssueButton };

export { S };
