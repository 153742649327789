import Cookies from "js-cookie";
import { localStorage, getFromEnv } from ".";

const storageKey = "access_token";
export const setAccessToken = token => localStorage.set(storageKey, token);
export const getAccessToken = () => localStorage.get(storageKey);
export const removeAccessToken = () => localStorage.remove(storageKey);

export const getAccessCookie = () => Cookies.get("heetch_auth_token");
export const setAccessCookie = access_token => {
  const expireIn = minutes => {
    const ret = new Date();
    ret.setTime(ret.getTime() + minutes * 60 * 1000);
    return ret;
  };
  const IS_PROD = process.env.NODE_ENV === "production";
  const IS_STAGING = !!getFromEnv("KALYKE_CLUSTER_ID");
  const cookieParams =
    IS_STAGING || IS_PROD
      ? {
          domain: IS_STAGING ? ".heetch.net" : ".heetch.com",
          sameSite: "None",
          secure: true,
          expires: expireIn(1),
        }
      : undefined;

  Cookies.set("heetch_auth_token", access_token, { ...cookieParams });
};
export const removeAccessCookie = () => {
  const IS_PROD = process.env.NODE_ENV === "production";
  const IS_STAGING = !!getFromEnv("KALYKE_CLUSTER_ID");
  const cookieParams =
    IS_STAGING || IS_PROD
      ? {
          domain: IS_STAGING ? ".heetch.net" : ".heetch.com",
        }
      : undefined;

  Cookies.remove("heetch_auth_token", { ...cookieParams });
};
