import { createContext, useContext, useEffect, useMemo, useState } from "react";
import PropTypes from "prop-types";
import { parsePhoneNumberFromString } from "libphonenumber-js";

import { getFromEnv } from "../../helpers";
import { IS_DEV, SCREEN_EVENT_NAME } from "../../constants";

const SCRIPT_ID = "segment-script";

const TrackingContext = createContext();

const isAlreadyInitialized = () => !!document.getElementById(SCRIPT_ID);

const initSegment = () => {
  if (isAlreadyInitialized()) return;
  /* eslint-disable */
  var analytics = (window.analytics = window.analytics || []);
  if (!analytics.initialize)
    if (analytics.invoked)
      window.console &&
        console.error &&
        console.error("Segment snippet included twice.");
    else {
      analytics.invoked = !0;
      analytics.methods = [
        "trackSubmit",
        "trackClick",
        "trackLink",
        "trackForm",
        "pageview",
        "identify",
        "reset",
        "group",
        "track",
        "ready",
        "alias",
        "debug",
        "page",
        "once",
        "off",
        "on",
      ];
      analytics.factory = function(t) {
        return function() {
          var e = Array.prototype.slice.call(arguments);
          e.unshift(t);
          analytics.push(e);
          return analytics;
        };
      };
      for (var t = 0; t < analytics.methods.length; t++) {
        var e = analytics.methods[t];
        analytics[e] = analytics.factory(e);
      }
      analytics.load = function(t, e) {
        var n = document.createElement("script");
        n.type = "text/javascript";
        n.async = !0;
        n.src =
          "https://cdn.segment.com/analytics.js/v1/" + t + "/analytics.min.js";
        n.id = SCRIPT_ID;
        var a = document.getElementsByTagName("script")[0];
        a.parentNode.insertBefore(n, a);
        analytics._loadOptions = e;
      };
      analytics.SNIPPET_VERSION = "4.1.0";
    }
  /* eslint-enable */
};

export const formatExtraData = (payload = {}) => {
  const { phone_number } = payload;
  return {
    ...payload,
    ...(phone_number && {
      country_code: parsePhoneNumberFromString(phone_number).country,
    }),
  };
};

export const trackEvent = (event, extra = {}) => {
  const payload = formatExtraData(extra);
  window.analytics.track(event, payload);
};

// eslint-disable-next-line @typescript-eslint/no-unused-vars
export const trackPage = (screen, { access_token, ...extra } = {}) => {
  const page = SCREEN_EVENT_NAME[screen];
  const payload = formatExtraData(extra);

  if (page) {
    window.analytics.page(page, payload);
  }
};

export const useTracking = () => {
  const context = useContext(TrackingContext);

  if (!context) {
    throw new Error(`useTracking must be used within a TrackingProvider`);
  }

  return context;
};

/* eslint-disable no-console */
export const TrackingProvider = ({ children, segmentKey, ...props }) => {
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    if (IS_DEV) {
      window.analytics = {
        page: (...data) => console.info("[tracking:page]", ...data),
        track: (...data) => console.info("[tracking:event]", ...data),
      };
    }

    if (!IS_DEV && !isAlreadyInitialized()) {
      initSegment();
      window.analytics.load(segmentKey);
    }
    setIsLoading(false);
  }, [segmentKey]);

  const value = useMemo(() => ({ trackEvent, trackPage }), []);

  return (
    <TrackingContext.Provider value={value} {...props}>
      {isLoading ? "" : children}
    </TrackingContext.Provider>
  );
};

TrackingProvider.propTypes = {
  children: PropTypes.node.isRequired,
  segmentKey: PropTypes.string,
};

TrackingProvider.defaultProps = {
  segmentKey: getFromEnv("SEGMENT_KEY"),
};
