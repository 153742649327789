import React, { useEffect } from "react";
import PropTypes from "prop-types";
import { InputField } from "@heetch/flamingo-react";

const FocusableInputField = ({ inputRef, ...props }) => {
  useEffect(() => {
    const input = inputRef.current;
    const onFocus = e => e.target.scrollIntoView();

    input.addEventListener("focus", onFocus);
    return () => input.removeEventListener("focus", onFocus);
  }, [inputRef]);

  return <InputField inputRef={inputRef} {...props} />;
};

FocusableInputField.propTypes = {
  inputRef: PropTypes.oneOfType([
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]),
};

FocusableInputField.defaultProps = {
  inputRef: React.createRef(),
};

export default FocusableInputField;
