import styled from "styled-components/macro";
import { MENU_BREAKPOINT } from "../../constants/vars";
import backgroundImage from "../../assets/background.jpg";

const Background = styled.div`
  background: url(${backgroundImage}) no-repeat center center fixed;
  background-size: cover;
  height: 100vh;
  overflow: hidden;
  @media (max-width: ${MENU_BREAKPOINT}px) {
    background-position: right;
  }
`;

const App = styled.div`
  display: flex;
  flex-direction: column;

  ${({ lang }) =>
    lang === "ar" &&
    `
      *:not(.ltr) {
        unicode-bidi: bidi-override;
        direction: rtl;
      }

      input,
      .intl-tel-input *,
      .selected-flag *,
      .VerificationCode * {
        unicode-bidi: embed !important;
        direction: initial !important;
      }

      .f-Helper {
        text-align: right;
        margin-right: 1rem;
      }

      #password {
        padding-right: 0;
      }

      .FbButton-logo {
        margin: 0 0 0 var(--f-space--m);
      }

      .f-Alert {
        text-align: right;
        padding-right: 2rem;
      }

      input#password::placeholder,
      input#email::placeholder,
      input#firstname::placeholder,
      input#lastname::placeholder {
        text-align: right;
      }

      input#password::placeholder {
        padding-right: 3rem
      }

    `}
`;

const Title = styled.div`
  font-size: 36px;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  width: 100%;
  max-width: 350px;
  font-family: "MarkOT-Ultra";
  -webkit-text-stroke-width: 1.5px;
  -webkit-text-stroke-color: white;
  color: transparent;
  line-height: initial;
  letter-spacing: 2px;
  text-transform: uppercase;

  @media (min-width: 370px) and (max-width: 1140px) {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  @media (min-width: 1141px) {
    text-align: center;
    font-size: 68px;
    max-width: 100%;
  }
`;

const PrivacyTerms = styled.a`
  color: white;
  text-align: center;
  position: absolute;
  width: 100%;
  font-weight: 900;
  bottom: 8px;
  line-height: 20px;
  font-size: 14px;
  text-decoration: underline;

  /* hide when mobile keyboard is open */
  @media (max-height: 500px) {
    display: none;
  }
`;

const Subtitle = styled.div`
  color: #8b99a2;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  padding: 1rem;
  text-align: center;
  line-height: 20px;
`;

const LoginPanel = styled.div`
  width: 343px;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  margin: 16px;
  @media (max-width: 375px) {
    width: auto;
  }
`;

const SquareWrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100vw;
  justify-content: center;
  align-items: center;
`;

const S = {
  App,
  Background,
  Title,
  LoginPanel,
  PrivacyTerms,
  SquareWrapper,
  Subtitle,
};

export { S };
