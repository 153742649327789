import i18n from "i18next";
import LanguageDetector from "i18next-browser-languagedetector";
import { initReactI18next } from "react-i18next";

import arTranslations from "./locales/ar.json";
import arDZTranslations from "./locales/ar-DZ.json";
import enTranslations from "./locales/en.json";
import frTranslations from "./locales/fr.json";
import nlTranslations from "./locales/nl.json";
import ptTranslations from "./locales/pt.json";

const initOpts = {
  fallbackLng: "fr",
  debug: process.env.NODE_ENV === "development",
  detection: {
    caches: null, // we don't want (yet) to store the user language
  },
  saveMissing: true,
  interpolation: {
    escapeValue: false,
  },
  resources: {
    ar: {
      translation: arTranslations,
    },
    "ar-DZ": {
      translation: arDZTranslations,
    },
    en: {
      translation: enTranslations,
    },
    fr: {
      translation: frTranslations,
    },
    nl: {
      translation: nlTranslations,
    },
    pt: {
      translation: ptTranslations,
    },
    react: {
      transKeepBasicHtmlNodesFor: ["br", "strong", "i", "p", "span"],
    },
  },
};

i18n.use(LanguageDetector).use(initReactI18next).init(initOpts);

export default i18n;
