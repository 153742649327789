import { useEffect, useState } from "react";
import { asyncWithLDProvider } from "launchdarkly-react-client-sdk";
import { deviceId } from "../../api";
import { getFromEnv } from "../../helpers";
import { useUserLocation } from "../../contexts/useUserLocation";

const KALYKE_CLUSTER_ID = getFromEnv("KALYKE_CLUSTER_ID");
const LAUNCHDARKLY_KEY = getFromEnv("LAUNCHDARKLY_KEY");

// subscribe for updates only for specified flags.
const flags = {
  "passenger-login-support-webpage": "https://heetch.typeform.com/to/nOaRYw",
  "passenger-driver-display-login-methods-in-homescreen": {
    apple: false,
    phone_password: true,
    facebook: true,
  },
};

const getProvider = ({ country }) =>
  asyncWithLDProvider({
    flags,
    clientSideID: LAUNCHDARKLY_KEY,
    user: {
      key: deviceId,
      custom: {
        country,
        platform: "web",
        cluster: KALYKE_CLUSTER_ID,
        "device-id": deviceId,
      },
    },
  });

const TIMEOUT = 2000;

// we don't want to render anything here
// but we need to return an actual React component
const LDTimeoutProvider = ({ children }) => children;

const timeoutPromise = () =>
  new Promise(resolve => {
    setTimeout(() => resolve(LDTimeoutProvider), TIMEOUT);
  });

const LDProvider = props => {
  const [provider, setProvider] = useState();
  const { country } = useUserLocation();

  useEffect(() => {
    Promise.race([getProvider({ country, ...props }), timeoutPromise()]).then(
      Provider => setProvider(<Provider {...props} />),
    );
  }, [country, props]);

  return provider || <></>;
};

export default LDProvider;
