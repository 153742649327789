import { useTranslation } from "react-i18next";
import SquareButton from "../SquareButton/SquareButton";
import { getFromEnv } from "../../helpers";

const HEETCH_URL = getFromEnv("HEETCH_URL");

const BecomeDriverButton = props => {
  const { t } = useTranslation();
  return (
    <a
      data-testid="become-driver-button"
      href={HEETCH_URL}
      target="_blank"
      rel="noopener noreferrer"
      {...props}
    >
      <SquareButton text={t("buttons.join")} backgroundColor="#FF4D95" />
    </a>
  );
};

export default BecomeDriverButton;
