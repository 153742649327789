/* eslint-disable no-undef */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { Button } from "@heetch/flamingo-react";
import qs from "query-string";

import "./AppleButton.css";
import { getFromEnv, getUrlParams } from "../../helpers";
import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS } from "../../constants";
import { SSOWindow } from "../../../@types";

const APPLE_REDIRECT_URI = getFromEnv("APPLE_REDIRECT_URI");

type Props = {
  onSigninSuccess: (data: AppleIDSignInOnSuccessEvent) => void;
  onSinginError: (data: AppleIDSignInOnErrorEvent) => void;
  language?: string;
  isLoading?: boolean;
  type: "continue" | "sign in";
};
declare let window: AppleWindow & SSOWindow;
const locales = {
  en: "en_US",
  fr: "fr_FR",
  pt: "pt_PT",
};
const FALLBACK_LANGUAGE = "fr";

const attachAppleScript = (locale: string) => {
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://appleid.cdn-apple.com/appleauth/static/jsapi/appleid/1/${locale}/appleid.auth.js`;
  document.head.append(script);
  return script;
};

const AppleButton: React.FC<Props> = ({
  language,
  onSigninSuccess,
  onSinginError,
  type,
  isLoading,
}: Props) => {
  const { trackEvent } = useTracking();
  const [appleScriptLoaded, setAppleScriptLoaded] = useState(false);
  const [appleScriptLoadedError, setAppleScriptLoadedError] = useState(false);
  const displayLanguage = language
    ? language.substr(0, 2).toLowerCase()
    : FALLBACK_LANGUAGE;
  const locale = locales[displayLanguage]
    ? locales[displayLanguage]
    : locales.en;

  useEffect(() => {
    const listenerRemoveFns: Array<() => void> = [];
    const initAppleScript = () => {
      try {
        if (window.AppleID) {
          window.AppleID.auth.init({
            clientId: "com.heetch.HeetchService",
            scope: "name email",
            redirectURI: APPLE_REDIRECT_URI,
            state: qs.stringify({
              ...getUrlParams(),
              auth_provider: "apple",
            }),
            usePopup: false,
          });
          setAppleScriptLoaded(true);
        } else {
          throw new Error("Script loaded but window.AppleID is missing");
        }
      } catch (e) {
        console.error(e);
        setAppleScriptLoadedError(true);
        trackEvent(SEGMENT_EVENTS.APPLE_SCRIPT_LOAD_ERROR);
      }
    };

    if (window.AppleID) {
      initAppleScript();
    } else {
      const script = attachAppleScript(locale);
      script.addEventListener("load", initAppleScript);
      listenerRemoveFns.push(() =>
        script.removeEventListener("load", initAppleScript),
      );
    }

    document.addEventListener(
      "AppleIDSignInOnSuccess",
      onSigninSuccess as EventListener,
    );

    document.addEventListener(
      "AppleIDSignInOnFailure",
      onSinginError as EventListener,
    );

    return () => {
      document.removeEventListener(
        "AppleIDSignInOnSuccess",
        onSigninSuccess as EventListener,
      );

      document.removeEventListener(
        "AppleIDSignInOnFailure",
        onSinginError as EventListener,
      );
    };
  }, [locale, onSigninSuccess, onSinginError, trackEvent]);

  return (
    <Button
      disabled={!appleScriptLoaded || appleScriptLoadedError}
      className="AppleButton"
      isLoading={isLoading || (!appleScriptLoaded && !appleScriptLoadedError)}
    >
      <div id="appleid-signin" data-type={type} />
    </Button>
  );
};

export default AppleButton;
