import { useState } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import { LocationDescriptor } from "history";

import Error from "../../components/Error";
import AuthRecoveryPanel from "../../components/panels/AuthRecovery";
import FbButton from "../../components/OAuthButtons/FbButton";
import I18n from "../../components/I18n";
import Layout from "../../components/Layout/Layout";
import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS } from "../../constants";
import {
  AuthScreenProps,
  AuthScreenState,
  AuthState,
  OAuthProvider,
} from "../../../@types";
import AppleButton from "../../components/OAuthButtons/AppleButton";
import { linkAppleSession } from "../../api";
import { getErrorCode, setAccessToken } from "../../helpers";
import { S } from "./styles";

// eslint-disable-next-line import/no-anonymous-default-export
export default (authProvider: OAuthProvider) => {
  const Recovery = ({
    errorCode,
    transitionTo,
    sessionState,
    history,
    ...props
  }: AuthScreenProps) => {
    const SegmentEvents = {
      continueTapped: {
        apple: SEGMENT_EVENTS.APPLE_RECOVERY_CONTINUE_TAPPED,
        facebook: SEGMENT_EVENTS.FACEBOOK_RECOVERY_CONTINUE_TAPPED,
      },
      issuesTapped: {
        apple: SEGMENT_EVENTS.APPLE_RECOVERY_ISSUES_TAPPED,
        facebook: SEGMENT_EVENTS.FACEBOOK_RECOVERY_ISSUES_TAPPED,
      },
    };

    const [error, setError] = useState<string>("");
    const [isPanelOpen, setIsPanelOpen] = useState(false);

    const handleAccountCreation = () => {
      // If the backend encounters an account already existing with the provided phone number
      // which has been authenticated using an oauth, it changes session.authentication_provider
      // to be of this OAuthProvider (e.g. Apple or Facebook).
      // This faces us with a difficulty, because session.authentication_provider should reflect
      // the user intent, and here, do to legacy reasons has been overridden by the backend.
      // We are counting on the implementation detail, that in this case the state will be of
      // authentication_required and in this case we know, that the user has tried to authenticate
      // using a phone number which is already attached to an OAuthProvider and therefore,
      // requires a password.
      // Otherwise, we know that the user simply tries a different OAuthProvider, and we should
      // let them continue with the creation of a new account by adding details.
      const resultingState: AuthState =
        sessionState === "authentication_required"
          ? "create_password"
          : "user_information";
      transitionTo({ state: resultingState });
    };

    const { trackEvent } = useTracking();
    const { i18n } = useTranslation();

    const trackContinueButton = () =>
      trackEvent(SegmentEvents.continueTapped[authProvider]);

    const trackIssueButton = () =>
      trackEvent(SegmentEvents.issuesTapped[authProvider]);

    const oAuthProviderTitle = `${authProvider
      .slice(0, 1)
      .toUpperCase()}${authProvider.slice(1).toLowerCase()}`;

    const redirect = ({ pathname = "/", state }: AuthScreenState) => {
      history.push({
        pathname,
        state,
      } as LocationDescriptor<any>);
    };

    return (
      <Layout
        title={
          <I18n
            raw
            id="oauthRecovery.title"
            values={{ authentication_provider: oAuthProviderTitle }}
          >
            <span className="ltr">{"{authentication_provider}"}</span>
          </I18n>
        }
        subtitle={
          <I18n
            raw
            id="oauthRecovery.helper"
            values={{
              Heetch: "Heetch",
              authentication_provider: oAuthProviderTitle,
            }}
          >
            <span className="ltr">{"{Heetch}"}</span>
            <span className="ltr">{"{authentication_provider}"}</span>
          </I18n>
        }
      >
        <AuthRecoveryPanel
          {...props}
          handleAccountCreation={handleAccountCreation}
          isOpen={isPanelOpen}
          onClose={() => setIsPanelOpen(false)}
          authProvider={authProvider}
        />

        {(errorCode || error) && <Error error={errorCode || error} />}

        {authProvider === "facebook" && (
          <FbButton onClick={trackContinueButton} recovery>
            <I18n id="buttons.continue-fb" />
          </FbButton>
        )}

        {authProvider === "apple" && (
          <AppleButton
            language={i18n.language}
            type="continue"
            onSigninSuccess={e => {
              linkAppleSession({
                code: e.detail.authorization.code,
              })
                .then(({ data }) => {
                  if (data.access_token) {
                    setAccessToken(data.access_token);
                  }
                  transitionTo(data);
                })
                .catch(err =>
                  redirect({ errorCode: getErrorCode(err) } as AuthScreenState),
                );
            }}
            onSinginError={() => setError("authentication_failed")}
          />
        )}

        <S.ConnexionIssueButton
          onClick={() => {
            trackIssueButton();
            setIsPanelOpen(true);
          }}
          {...props}
        >
          <I18n id="buttons.connexion-issue" />
        </S.ConnexionIssueButton>
      </Layout>
    );
  };

  Recovery.propTypes = {
    errorCode: PropTypes.string,
    transitionTo: PropTypes.func.isRequired,
  };

  Recovery.defaultProps = {
    errorCode: undefined,
  };

  return Recovery;
};
