import { useEffect } from "react";
import PropTypes from "prop-types";
import Layout from "../../components/Layout/Layout";
import { STATES } from "../../constants";

const TerminatedScreen = ({ transitionTo }) => {
  useEffect(() => {
    transitionTo({
      state: STATES.PHONE_OR_OAUTH,
    });
  }, [transitionTo]);

  return <Layout withRestartLink={false} />;
};

TerminatedScreen.propTypes = {
  transitionTo: PropTypes.func.isRequired,
};

export default TerminatedScreen;
