import Proptypes from "prop-types";
import { Spinner, Icon } from "@heetch/flamingo-react";
import { S } from "./styles";

const SquareButton = ({
  backgroundColor,
  text,
  onClick,
  disabled,
  isLoading,
  IconCheck,
  ...props
}) => (
  <S.SquareButton
    backgroundColor={backgroundColor}
    disabled={disabled}
    isLoading={isLoading}
    data-testid="SquareButton"
    onClick={!isLoading && !disabled ? onClick : undefined}
    {...props}
  >
    {IconCheck && (
      <Icon data-testid="success-icon" icon={Icon.ICONS.IconCheck} />
    )}
    {isLoading ? <Spinner size="m" data-testid="Spinner" /> : <>{text}</>}
  </S.SquareButton>
);

SquareButton.propTypes = {
  backgroundColor: Proptypes.string,
  text: Proptypes.string,
  disabled: Proptypes.bool,
  onClick: Proptypes.func,
  isLoading: Proptypes.bool,
  IconCheck: Proptypes.bool,
};

SquareButton.defaultProps = {
  backgroundColor: undefined,
  text: undefined,
  disabled: false,
  isLoading: undefined,
  onClick: undefined,
  IconCheck: undefined,
};
export default SquareButton;
