import styled from "styled-components/macro";

const SquareButton = styled.button`
  padding: 18px;
  width: 100%;
  color: ${({ disabled }) =>
    disabled ? "var(--f-color-text--tertiary)" : "white"};
  background-color: ${({ disabled, backgroundColor }) =>
    disabled ? "var(--f-color-element--primary)" : backgroundColor};
  border-radius: 5px;
  border: none;
  font-weight: 900;
  font-size: 18px;
  margin: 16px 0px;
  :hover,
  :focus {
    cursor: ${({ disabled }) => (disabled ? "default" : "pointer")};
    opacity: ${({ disabled }) => (disabled ? "100%" : "80%")};
    outline: none;
  }
`;

const S = { SquareButton };
export { S };
