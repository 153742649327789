import { useEffect } from "react";
import PropTypes from "prop-types";
import { useTranslation } from "react-i18next";
import RestartLink from "../RestartLink/Restart";
import Logo from "../Logo/Logo";

import { getFlow } from "../../helpers";
import { S } from "./styles";

const Layout = ({ children, subtitle, title, withRestartLink }) => {
  const flow = getFlow(); // login || signup
  const { t, i18n } = useTranslation();

  useEffect(() => {
    document.title = t(`global.page-title.${flow}`);
  }, [flow, t]);

  const userLang = i18n?.languages[0].substring(0, 2);

  return (
    <S.Background>
      <S.App lang={userLang}>
        <Logo />
        <S.SquareWrapper data-testid="wrapper">
          <S.Title data-testid="title">{title}</S.Title>
          <S.LoginPanel>
            {subtitle && (
              <S.Subtitle data-testid="subtitle">{subtitle}</S.Subtitle>
            )}
            {children}
          </S.LoginPanel>
          {withRestartLink && <RestartLink restart />}
        </S.SquareWrapper>
        {(userLang === "fr" || userLang === "en") && (
          <S.PrivacyTerms href={`https://www.heetch.com/${userLang}/privacy`}>
            {t("global.page-terms.privacy-link")}
          </S.PrivacyTerms>
        )}
      </S.App>
    </S.Background>
  );
};

Layout.propTypes = {
  children: PropTypes.node,
  subtitle: PropTypes.node,
  title: PropTypes.node,
  withRestartLink: PropTypes.bool,
};

Layout.defaultProps = {
  children: undefined,
  subtitle: undefined,
  title: undefined,
  withRestartLink: true,
};

export default Layout;
