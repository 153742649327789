import { useState } from "react";
import PropTypes from "prop-types";
import cx from "classnames";
import { Field } from "react-final-form";
import PhoneInput from "react-phone-input-2";
import examples from "libphonenumber-js/examples.mobile.json";
import { getExampleNumber } from "libphonenumber-js";
import { Helper } from "@heetch/flamingo-react";
import { useLocation } from "react-router-dom";

import {
  checkUndefinedOrNullString,
  isPhoneNumber,
  isRequired,
  validators,
} from "../../helpers";
import { PREFERRED_COUNTRIES } from "../../constants";
import "react-phone-input-2/lib/high-res.css";
import { S } from "./styles";

const PhoneNumberField = ({ country, disabled }) => {
  const [placeholder, setPlaceholder] = useState(
    getExampleNumber(country, examples),
  );

  const phoneNumber = checkUndefinedOrNullString(
    new URLSearchParams(useLocation()?.search).get("phone"),
  );

  return (
    <S.PhoneNumberWrapper>
      <Field
        name="phone_number"
        validate={validators.compose(isRequired, isPhoneNumber)}
        defaultValue={phoneNumber}
      >
        {({
          input: { name, onChange, onBlur, value: initialValue, ...input },
          meta,
        }) => (
          <div
            className={cx("PhoneNumberField", {
              "is-invalid": meta.touched && meta.error,
            })}
          >
            <PhoneInput
              autoFocus
              inputProps={{
                id: name,
                autoFocus: true,
                className: "f-FormEl",
              }}
              disabled={disabled}
              country={country?.toLowerCase()}
              id={name}
              preferredCountries={PREFERRED_COUNTRIES}
              preserveOrder={["preferredCountries"]}
              enableLongNumbers
              countryCodeEditable={false}
              disableCountryGuess
              onChange={(value, nation) => {
                onChange(value);
                setPlaceholder(
                  value === nation.dialCode
                    ? getExampleNumber(
                        nation.countryCode.toUpperCase(),
                        examples,
                      )
                    : null,
                );
              }}
              onBlur={onBlur}
              value={initialValue}
              {...input}
            />
            {meta.touched && meta.error && (
              <Helper
                data-testid="error-message"
                className="PhoneNumberField-error"
              >
                {meta.error}
              </Helper>
            )}
            {placeholder && !phoneNumber && (
              <S.Placeholder length={initialValue?.length}>
                {placeholder.formatNational().replace(/^0/, "")}
              </S.Placeholder>
            )}
          </div>
        )}
      </Field>
    </S.PhoneNumberWrapper>
  );
};

PhoneNumberField.propTypes = {
  disabled: PropTypes.bool,
  country: PropTypes.string.isRequired,
};

PhoneNumberField.defaultProps = {
  disabled: false,
};

export default PhoneNumberField;
