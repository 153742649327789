import React from "react";
import { Trans, useTranslation } from "react-i18next";

type Props = {
  children?: React.ReactChild | React.ReactChild[];
  id: string;
  raw?: boolean;
  values?: {
    [key: string]: any;
  };
};

const I18n = ({ id, ...props }: Props) => {
  const { ready } = useTranslation("translation");
  return ready ? <Trans i18nKey={id} {...props} /> : null;
};

export default I18n;
