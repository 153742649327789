import styled from "styled-components";
import { MENU_BREAKPOINT } from "../../constants/vars";
import logo from "../../assets/logo-white.svg";

const Header = styled.div`
  position: absolute;
  top: 2rem;
  left: 1rem;

  @media (min-width: 420px) {
    left: 2rem;
  }

  /* hide when mobile keyboard is open */
  @media (max-height: 530px) {
    display: none;
  }

  @media (min-width: 800px) {
    top: 60px;
    left: 130px;
  }
`;

const HeaderLogo = styled.img.attrs({
  src: logo,
})`
  display: block;
  width: 90px;
  height: auto;

  @media (max-width: ${MENU_BREAKPOINT}px) {
    width: 121px;
  }
`;

const S = { Header, HeaderLogo };

export { S };
