/* eslint-disable no-console */
import * as Sentry from "@sentry/browser";
import { getFromEnv } from "../helpers";

const KALYKE_CLUSTER_ID = getFromEnv("KALYKE_CLUSTER_ID");
const SENTRY_DSN = getFromEnv("SENTRY_DSN");
const SENTRY_ENVIRONMENT = getFromEnv("SENTRY_ENVIRONMENT");

export const init = () => {
  if (!SENTRY_DSN) return;

  Sentry.init({
    dsn: SENTRY_DSN,
    environment: SENTRY_ENVIRONMENT,
    cluster_id: KALYKE_CLUSTER_ID,
    beforeBreadcrumb({ data = {}, ...breadcrumb }) {
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      const { access_token, ...filteredData } = data;
      return { ...breadcrumb, data: filteredData };
    },
  });

  Sentry.setTag("service-name", "user-account-authentication-portal");
};

export const setTag = (...args) => {
  if (!SENTRY_DSN) {
    console.info("[sentry:setTag]", ...args);
    return;
  }

  Sentry.setTag(...args);
};

export const addBreadcrumb = (...args) => {
  if (!SENTRY_DSN) {
    console.info("[sentry:addBreadcrumb]", ...args);
    return;
  }

  Sentry.addBreadcrumb(...args);
};
