import { createRef, useState } from "react";
import PropTypes from "prop-types";
import { Form, Field } from "react-final-form";
import { useTranslation } from "react-i18next";

import Error from "../../components/Error";
import Layout from "../../components/Layout/Layout";
import I18n from "../../components/I18n";
import PasswordField from "../../components/PasswordField/PasswordField";
import PasswordRecoveryPanel from "../../components/panels/PasswordRecovery";
import { authenticateSession } from "../../api";
import { displayError, isRequired } from "../../helpers";
import { useTracking } from "../../contexts/useTracking";
import { SEGMENT_EVENTS, STATES } from "../../constants";
import SquareButton from "../../components/SquareButton/SquareButton";
import { S } from "./styles";

const EnterPasswordScreen = ({
  phone_number,
  transitionTo,
  authentication_provider,
}) => {
  const [error, setError] = useState(false);
  const [isPanelOpen, setIsPanelOpen] = useState(false);
  const { t } = useTranslation();

  const inputRef = createRef();
  const { trackEvent } = useTracking();

  const trackContinueButton = () =>
    trackEvent(SEGMENT_EVENTS.PASSWORD_CONTINUE_TAPPED, {
      phone_number,
    });

  const trackIssueButton = () =>
    trackEvent(SEGMENT_EVENTS.PASSWORD_ISSUES_TAPPED, {
      phone_number,
    });

  const openPanel = () => setIsPanelOpen(true);
  const closePanel = () => setIsPanelOpen(false);

  const handleAccountCreation = () => {
    let state = STATES.CREATE_PASSWORD;
    if (
      authentication_provider === "apple" ||
      authentication_provider === "facebook"
    ) {
      state = STATES.USER_INFORMATION;
    }
    transitionTo({ state });
  };

  const onSubmit = formData =>
    authenticateSession(formData)
      .then(({ data }) => transitionTo(data))
      .catch(displayError(setError));

  return (
    <Layout
      title={<I18n id="enterPassword.title" />}
      subtitle={<I18n id="enterPassword.helper" />}
    >
      <PasswordRecoveryPanel
        isOpen={isPanelOpen}
        handleAccountCreation={handleAccountCreation}
        onClose={closePanel}
        phone_number={phone_number}
      />

      {error && <Error error={error} />}

      <Form onSubmit={onSubmit}>
        {({ handleSubmit, submitting }) => (
          <form onSubmit={handleSubmit}>
            <Field name="password" validate={isRequired}>
              {({ input, meta }) => (
                <PasswordField
                  autoFocus
                  invalid={meta.touched && meta.invalid}
                  autoComplete="current-password"
                  inputRef={inputRef}
                  {...(meta.touched && { helper: meta.error })}
                  {...input}
                />
              )}
            </Field>

            <SquareButton
              disabled={submitting}
              isLoading={submitting}
              onClick={trackContinueButton}
              type="submit"
              text={t("buttons.continue")}
              backgroundColor="rgb(77, 148, 255)"
            />
          </form>
        )}
      </Form>

      <S.ConnexionIssueButton
        data-testid="issue-btn"
        onClick={() => {
          trackIssueButton();
          openPanel();
        }}
      >
        <I18n id="buttons.connexion-issue" />
      </S.ConnexionIssueButton>
    </Layout>
  );
};

EnterPasswordScreen.propTypes = {
  phone_number: PropTypes.string.isRequired,
  transitionTo: PropTypes.func.isRequired,
  authentication_provider: PropTypes.string.isRequired,
};

export default EnterPasswordScreen;
