import styled from "styled-components/macro";
import { Button } from "@heetch/flamingo-react";

const FacebookLink = styled.div`
  font-style: normal;
  font-weight: 900;
  font-size: 15px;
  line-height: 24px;
  display: flex;
  text-decoration: underline;
  color: #8b99a2;
  place-content: space-evenly;
  cursor: default;
`;

const FacebookButton = styled(Button)`
  padding: 18px;
  width: 100%;
  color: white;
  border-radius: 5px;
  font-weight: 900;
  font-size: 18px;
  margin: 16px 0px;
  background: red;
  background-color: #1778f2 !important;
  :hover,
  :focus {
    cursor: pointer;
    opacity: 80%;
    outline: none;
  }
  .FbButton-logo {
    margin-right: var(--f-space--m);
    height: 16px;
  }
`;

const S = { FacebookLink, FacebookButton };

export { S };
