import { useEffect } from "react";
import ReactDOM from "react-dom";
import { SidePanel } from "@heetch/flamingo-react";
import styled from "styled-components/macro";
import i18n from "../../i18n";

const StyledSidePanel = styled(SidePanel)`
  ${({ lang }) =>
    lang === "ar" &&
    `
      *:not(button, h1, .ltr) {
        text-align: right;
        unicode-bidi: bidi-override;
        direction: rtl;
      }
    `}
`;

const el = document.createElement("div");
const portalRoot = document.getElementById("portal-root");

const PortalPanel = props => {
  useEffect(() => {
    portalRoot.appendChild(el);
    return () => portalRoot.removeChild(el);
  }, []);

  return ReactDOM.createPortal(
    <StyledSidePanel
      closesOnOverlayClick
      lang={i18n?.language?.substring(0, 2)}
      {...props}
    />,
    el,
  );
};

export default PortalPanel;
