import { Route, BrowserRouter, Switch } from "react-router-dom";
import Manager from "../Manager";
import LDProvider from "../LDProvider";
import LogoutScreen from "../../screens/Logout";
import OAuthCallbackScreen from "../../screens/OAuthCallback";
import { TrackingProvider } from "../../contexts/useTracking";
import { UserLocationProvider } from "../../contexts/useUserLocation";
import * as Sentry from "../../services/Sentry";

Sentry.init();

const App = () => (
  <UserLocationProvider>
    <LDProvider>
      <TrackingProvider>
        <BrowserRouter>
          <Switch>
            <Route path="/logout" component={LogoutScreen} />
            <Route path="/oauth_callback" component={OAuthCallbackScreen} />
            <Route component={Manager} />
          </Switch>
        </BrowserRouter>
      </TrackingProvider>
    </LDProvider>
  </UserLocationProvider>
);

export default App;
