import styled from "styled-components/macro";

const PhoneNumberWrapper = styled.div`
  margin-bottom: 24px;
  .f-FormEl {
    border: none;
  }
  input {
    width: 100%;
    height: 38px;
    background: none;
    font-size: 16px;
    padding-left: 63px;
    font-weight: 900;
    letter-spacing: 0.02em;
    color: #000 !important;
  }
  .react-tel-input {
    direction: ltr;
    font-family: Arial, sans-serif !important;
    margin-bottom: 1rem;
    margin-top: 0.6rem;
    padding: 0 1rem;
    z-index: 2;
  }
  .react-tel-input .flag {
    width: 24px;
  }

  .react-tel-input .selected-flag .arrow {
    width: 9px;
    height: 9px;
    margin-left: 3px;
    margin-top: -4px;
    border-top: 2px solid rgb(0, 0, 0);
    border-right: 2px solid rgb(0, 0, 0);
    border-left: unset;
    border-bottom: unset;
    transform: rotate(135deg);
  }
  *:focus {
    outline: none;
  }
  .PhoneNumberField .f-FormEl {
    border-bottom: 1px solid;
    border-color: #dde3ee;
  }
  .PhoneNumberField input:focus {
    border-bottom: 1px solid var(--f-color-brandSecondary) !important;
  }
  .PhoneNumberField.is-invalid .f-FormEl {
    border-bottom: 1px solid;
    border-color: #cd2703 !important;
  }
  .PhoneNumberField {
    position: relative;
  }
  .PhoneNumberField .intl-tel-input {
    display: block;
  }
  .PhoneNumberField .selected-flag,
  .react-tel-input .flag-dropdown {
    direction: ltr;
    height: 35px;
    width: 56px;
    top: 0;
    border: none;
    border-radius: 0;
    background-color: white !important;
  }
  .PhoneNumberField input:-webkit-autofill {
    box-shadow: 0 0 0px 1000px white inset !important;
  }
  .f-Helper {
    position: absolute;
  }
  .f-Helper.PhoneNumberField-error {
    color: #cd2703 !important;
    font-size: 12px;
    font-weight: 500;
    left: 16px;
    top: 44px;
  }
`;

const Placeholder = styled.small`
  position: absolute;
  font-family: Arial, sans-serif;
  font-size: 16px;
  top: 9px;
  left: ${({ length }) =>
    length > 2 ? (length / 2) * length + length + 118 : 118}px;
  color: #8b99a2 !important;
  font-weight: 900;
  letter-spacing: 0.02em;
`;

const S = { PhoneNumberWrapper, Placeholder };

export { S };
