import styled from "styled-components/macro";
import { Button } from "@heetch/flamingo-react";

const PhoneNumber = styled.span`
  font-size: var(--f-fontSize--l);
  color: var(--f-color-brandSecondary);
`;

const TryAgainButton = styled(Button)`
  padding: 18px;
  width: 100%;
  color: white;
  border-radius: 5px;
  font-weight: 900;
  font-size: 18px;
  margin: 16px 0px;
  background-color: #4d94ff !important;
  :hover,
  :focus {
    cursor: pointer;
    opacity: 80%;
    outline: none;
  }
`;

const S = { PhoneNumber, TryAgainButton };

export { S };
