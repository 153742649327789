import { useEffect } from "react";
import PropTypes from "prop-types";
import { Alert, Link } from "@heetch/flamingo-react";

import I18n from "../I18n";
import i18n from "../../i18n";

import { useTracking } from "../../contexts/useTracking";
import { EMAIL_CONTACT_LINK, SEGMENT_EVENTS } from "../../constants";

const Error = ({ error }) => {
  const { trackEvent } = useTracking();
  const i18nPath = i18n.exists(`errors.${error}`)
    ? `errors.${error}`
    : "errors.generic";

  useEffect(() => {
    if (error === "authentication_failed") {
      trackEvent(SEGMENT_EVENTS.ACCOUNT_DEACTIVATED_SHOWN);
    }
  }, [error, trackEvent]);

  return (
    <Alert type={Alert.TYPES.ERROR} title={<I18n id={`${i18nPath}.title`} />}>
      <I18n raw id={`${i18nPath}.message`}>
        <Link data-testid="contact-link" href={EMAIL_CONTACT_LINK} />
      </I18n>
    </Alert>
  );
};

Error.propTypes = {
  error: PropTypes.string,
};

Error.defaultProps = {
  error: "generic",
};

export default Error;
